import React from 'react'
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              В мини-группе <br /> (По средам)
            </td>
            <td>
              $130 / чел <br />
              (при одноместном размещении доплата - $30)
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 3-х взрослых)
            </td>
            <td>$280 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>$220 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 6-и взрослых )
            </td>
            <td>$165 / чел</td>
          </tr>
        </tbody>
      </Table>
      <p>
        *В случае, если группа (4 чел) не наберется, организуем индивидуальную
        экскурсию по скидочной цене.
      </p>
    </div>
  );
}

export default Pricetable