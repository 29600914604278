import React from "react";
import { Link } from "react-router-dom";
import Hero from "./Hero";
import Card from "react-bootstrap/Card";
import { FaCheck } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import Photogrid from "./Photogrid";
import Footer from "./Footer";
import ExploreImage from "../../Images/Heros/Hero2.jpg";
import CarDriver from "../../Images/Renting/cardrivermain.jpg"


const Landing = () => {
  return (
    <div className="main-text">
      <Hero />
      {/* Numbers */}
      <div className="container p-0 mt-4">
        <div className="container p-0">
          <div className="row  br col-12 p-0 mx-auto">
            <h3 className="text-center">Yamu tours в цифрах в 2023</h3>
            <div className="row col-12 p-0 mx-auto justify-content-center mt-3">
              <div className="col-12 col-md-2 my-2">
                <h4 className="text-center">100+</h4>
                <p className="text-center">Трансферов</p>
              </div>
              <div className="col-12 col-md-2 my-2">
                <h4 className="text-center">200+</h4>
                <p className="text-center">Экскурсии</p>
              </div>
              <div className="col-12 col-md-2 my-2">
                <h4 className="text-center">1500+</h4>
                <p className="text-center">Доволных туристов</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-3">
        <div className="row mt-3">
          <div className="col-12 col-md-5 row p-1 mx-auto">
            <Link to="/tourbuilder" className="p-0 no-dec text-dark">
              <div className="col-12  d-flex border-thin">
                <div className="col-12">
                  <h5 className="text-center my-2">Туры</h5>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-5 row p-1 mx-auto">
            <Link to="/excursions" className="p-0 no-dec text-dark">
              <div className="col-12  d-flex border-thin">
                <div className="col-12">
                  <h5 className="text-center my-2">Экскурсии</h5>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h2 className="mt-2 deco-text text-center font-800">Популярные туры</h2>
        <div className="row col-12 mx-auto p-0">
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src={ExploreImage}
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Тайны Цейлона</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Двухдневная экскурсия по самым популярным и живописным местам Шри-Ланки с
                  южного побережья Шри-Ланки.
                </p>
                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Проживание с питанием</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamuexplore" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://live.staticflickr.com/7552/15654125258_89e8b8ba0f_h.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Сафари в Ялу</h3>
              </Card.Title>
              <Card.Text>  
                <p className="text-center">
                  Однодневный тур в национальный парк Яла с фотоэкскурсией по
                  южному побережью.
                </p> 

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamuyala" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://voyageinstyle.net/wp-content/uploads/2020/01/Diluyama-m-river2-scaled.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Элла за один день</h3>
              </Card.Title>
              <Card.Text className="text-center">
                <p className="text-center">
                  Посетите горы, чайные плантации, водопады, всемирно известный
                  поезд и многое другое за один день.
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>

                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamuella" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="container mt-4 p-0">
        <h2 className="col-12 my-3 deco-text text-center font-800">
          Машина с Водителем
        </h2>
        <div className="row col-12 p-0 mx-auto ">
          <div className="col-12 col-md-8 mx-auto p-0">
            <img src={CarDriver} alt="" className="cardrivermain" />
          </div>
          <div className=" col-12 col-md-4 mx-auto p-0">
            <div className="col-11 mx-auto p-0 row my-2">
              <div className="col-1">
                <FaCheck />
              </div>
              <div className="col-11">
                Квалифицированные водители с лицензией Министерства туризма.{" "}
              </div>
            </div>
            <div className="col-11 mx-auto p-0 row my-2">
              <div className="col-1">
                <FaCheck />
              </div>
              <div className="col-11">
                Чистые и комфортабельные автомобили.{" "}
              </div>
            </div>
            <div className="col-11 mx-auto p-0 row my-2">
              <div className="col-1">
                <FaCheck />
              </div>
              <div className="col-11">
                Англоговорящий и русскоговорящие водители.
              </div>
            </div>
            <div className="col-11 mx-auto p-0 row my-2">
              <div className="col-1">
                <FaCheck />
              </div>
              <div className="col-11">
                Путешествуйте в своем собственном темпе.
              </div>
            </div>
            <div className="col-11 mx-auto p-0 row my-2">
              <div className="col-1">
                <FaCheck />
              </div>
              <div className="col-11">
                Топливо, парковка, плата за проезд, проживание водителя и
                питание включены в стоимость.{" "}
              </div>
            </div>
            <Link
              to="/carrental"
              className="p-0 no-dec text-dark col-9 mx-auto row my-4 makebutton"
            >
              <div className="col-8 mx-auto yellow-box d-flex">
                <h4 className="m-auto text-center p-3">Подробнее</h4>
              </div> 
            </Link>
            {/* <div className="col-9 mx-auto p-0 row my-4 makebutton">
              <a
                href="https://t.me/yamutours1"
                className="p-0 no-dec text-dark"
              >
                <div className="col-8 mx-auto yellow-box d-flex">
                  <h4 className="m-auto text-center p-3">Подробнее</h4>
                </div>
              </a>
            </div> */}
          </div>
          <div className="col-4"></div>
        </div>
        {/* <Photogrid className="mx-auto" /> */}
        {/* <Gallary/> */}
      </div>
      <div className="container p-0">
        <div className="p-0 deco-text my-3">
          <h2 className="mt-2 deco-text text-center font-800">
            Отзывы наших Туристов
          </h2>
          <div className="col-12 col-md-6 p-0 mx-auto">
            <Carousel variant="dark" className="p-0 min-height-review">
              <Carousel.Item>
                <div className="review-box row col-12 py-5">
                  <div className="d-flex col-12">
                    <div className="client-anna mx-auto"></div>
                  </div>
                  <div className="col-8 mx-auto mt-2">
                    {" "}
                    <p className="text-center">Анна </p>
                  </div>
                  <div className="col-9 mx-auto">
                    <p className="text-center">
                      Спасибо большое вам и вашему чудесному гиду Даммике. Очень
                      интересная экскурсия, время пролетело незаметно. Желаем
                      вашей компании дальнейшего процветания и побольше
                      туристов! <br />
                      25.07.2022
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="review-box row col-12 py-5 ">
                  <div className="d-flex col-12">
                    <div className="client-nikita mx-auto"></div>
                  </div>
                  <div className="col-8 mx-auto mt-2">
                    {" "}
                    <p className="text-center">Никита</p>
                  </div>
                  <div className="col-9 mx-auto">
                    <p className="text-center">
                      Спасибо большое. Тур нам очень понравился, гид чудесный -
                      ему отдельное спасибо! Очень общительный, много рассказал
                      интересных фактов о парке Яла и о Шри-Ланке в целом!
                      <br />
                      02.08.2022
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="review-box row col-12 py-5">
                  <div className="d-flex col-12">
                    <div className="client-ivan mx-auto"></div>
                  </div>
                  <div className="col-8 mx-auto mt-2">
                    {" "}
                    <p className="text-center">Иван</p>
                  </div>
                  <div className="col-8 mx-auto">
                    <p className="text-center">
                      Все прошло отлично, нам очень понравилось, все удалось,
                      кроме купания в водопаде, т.к. был дождь. Гид помог даже с
                      организацией спонтанного спуска на зиплайне
                      <br />
                      02.05.2022
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="review-box row col-12 py-5">
                  <div className="d-flex col-12">
                    <div className="client-vladmir mx-auto"></div>
                  </div>
                  <div className="col-8 mx-auto mt-2">
                    {" "}
                    <p className="text-center">Владимир</p>
                  </div>
                  <div className="col-8 mx-auto">
                    <p className="text-center">
                      Все прошло отлично, нам понравилось. Гид и водитель
                      настоящие профессионалы, во всем нам помогали и
                      рассказывали много интересного. Всем рекомендую.
                      <br />
                      07.05.2022
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            <a
              // href= "https://t.me/yamureviews"

              // target="_blank"
              href="https://t.me/yamureviews"
              className="p-0 no-dec text-dark"
            >
              <div className="px-3">
                <div className="col-12  d-flex border-thin">
                  <div className="col-12">
                    <h5 className="text-center my-2">Все отзывы</h5>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="container mt-4 p-0">
        <h2 className="col-12 my-3 deco-text text-center font-800">
          Наши Туристы
        </h2>
        <Photogrid className="mx-auto" />
        {/* <Gallary/> */}
      </div>
      <div className="container-fluid mt-4 p-0 ">
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
