import React from 'react'
import { Link } from "react-router-dom";
import Hero from "./Hero";
import Card from "react-bootstrap/Card";
import { FaCheck, } from "react-icons/fa";
import Footer from "../Maipage.js/Footer"
import YachtImage from "../../Images/Yacht/Yacht3.jpg"
import RidiyagamaImg from "../../Images/Ridiyagama/i9.JPG"
import EllaIMG from "../../Images/Tourists/i40.jpeg"
import ExploreImage from "../../Images/Tourists/I25.JPG";

const Excursions = () => {
  return (
    <div>
      <Hero />

      <div className="container my-5">
        <h2 className="mt-2 deco-text text-center font-800">Популярные туры</h2>
        <div className="row col-12 mx-auto p-0">
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src={ExploreImage}
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center"> Тайны Цейлона</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  2-х дневная экскурсия по самым популярным и живописным местам
                  Шри-Ланки с южного побережья Шри-Ланки.
                </p>
                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Проживание с питанием</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamueastexplore" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://live.staticflickr.com/7552/15654125258_89e8b8ba0f_h.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Сафари в Ялу</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Однодневный тур в национальный парк Яла с фотоэкскурсией по
                  южному побережью.
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный сафари водитель</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamuyala" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img variant="top" src={EllaIMG} className="card-image-tour" />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Элла за один день</h3>
              </Card.Title>
              <Card.Text className="text-center">
                <p className="text-center">
                  Посетите горы, чайные плантации, водопады, всемирно известный
                  поезд и многое другое за один день.
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>

                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamuella" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://www.ceylonexpeditions.com/medias/destination_places/big/62/wildlife-12days-1.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Миннерия Сафари</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Однодневный тур в национальный парк Миннерия
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный сафари водитель</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamuminneriya" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src={YachtImage}
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Прогулка на Яхте</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Закат на Яхте в индийском океане - 3-х часовая прогулка
                </p>
                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Люкс яхта</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Легкий перекус</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Сап-серфинг</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Рыбалка</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link
                    to="/yamusunsetyachteast"
                    className="p-0 no-dec text-dark"
                  >
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src={RidiyagamaImg}
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Ридиягама Сафари</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Сафари на полдня в национальном парке Ридиягама.
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный сафари водитель</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Кормление животных</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/Ridiyagamasafari" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://wowiwalkers.com/wp-content/uploads/2023/10/Header_Sigiriya-Rock_Sri-Lanka-1.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Сигирия</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Однодневная экскурсия в Сигирию и окрестности
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный сафари водитель</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Кормление слонов</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamusigiriya" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://media-cdn.tripadvisor.com/media/attractions-splice-spp-720x480/0b/21/6e/58.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Тринкомали</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Однодневная экскурсия в Тринкомали и окрестности
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный сафари водитель</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Кормление оленей</p> 
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamutrinco" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://live.staticflickr.com/495/20211903265_50dfade589_4k.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Кэнди за Один День</h3>
              </Card.Title>
              <Card.Text className="text-center">
                <p className="text-center">
                  Однодневный тур в древний город Канди, чтобы ближе
                  познакомиться с природой и историей Шри-Ланки.
                </p>

                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Транспорт со всеми расходами</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Входные билеты</p>
                  </div>

                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Закуски + вода</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/yamukandy" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Подробности</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="container my-5">
        <h2 className="mt-2 deco-text text-center font-800">
          Построить Индивидуальный Маршрут
        </h2>
        <div className="row col-12 mx-auto p-0">
          <Card style={{ width: "24rem" }} className="mx-auto p-0 my-2">
            <Card.Img
              variant="top"
              src="https://live.staticflickr.com/667/31452258703_caddab5164_5k.jpg"
              className="card-image-tour"
            />
            <Card.Body>
              <Card.Title>
                <h3 className="font-800 text-center">Индивидуальный Маршрут</h3>
              </Card.Title>
              <Card.Text>
                <p className="text-center">
                  Уникальная возможность построить свой собственный маршрут по
                  местам, которые вы хотели бы посетить.
                </p>
                <div className=" col-12 p-0">
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Личный гид / водитель </p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Выбор достопримечательностей</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Выбор отелей</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Экскурсия по вашему бюджету</p>
                  </div>
                  <div className="col-12 d-flex">
                    <FaCheck className="my-auto green-text" />{" "}
                    <p className="my-auto ml-2">Возможность изменить маршрут</p>
                  </div>
                </div>
                <div className="col-12 p-0 row mx-auto mt-3">
                  <Link to="/tourbuilder" className="p-0 no-dec text-dark">
                    <div className="col-12  d-flex border-thin">
                      <div className="col-12">
                        <h5 className="text-center my-1">Построить маршрут</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="container p-0"></div>
      <Footer />
    </div>
  );
}

export default Excursions 