import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes,  } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import Pricetable from './Pricetable';
import Booktour from '../../Minicomponents/Booktour';
import Gallary8 from './Gallary8';

import Ravanapic from "../../../Images/Tourists/i42.jpg";
import Adampic from "../../../Images/Tourists/i35.jpg";
import ninepic from "../../../Images/Tourists/i39.jpeg";
import trainpic from "../../../Images/Tourists/i33.jpg";
import teapic from "../../../Images/Tourists/i40.jpeg";
import rambodapic from "../../../Images/Tourists/i31.JPG";
import ambuluwawapic from "../../../Images/Tourists/I25.JPG";
// import amithpic from '../../../Images/Tourists/I25.JPG'
import dambullapic from "../../../Images/Tourists/i38.JPG";
import elephantpic from "../../../Images/Tourists/i43.jpeg";
import sigiriyapic from "../../../Images/Tourists/i41.jpeg";

const Details = () => {
    return (
      <div className="container d-flex p-0">
        <div className="col-12 my-3 p-1 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">2 дня</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Стоимость</h5>
                <p className="text-center my-auto">от $140</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Мини-групповые туры</h5>
                <p className="text-center my-auto">По средам</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              Самые популярные достопримечательности Шри-Ланки, незабываемые
              воспоминания, уникальные фотографии и увлекательные виды - и все
              это за 2 дня индивидуально или в мини группе до 10 туристов.
              Лицензированный опытный русскоговорящий гид который получил высшее
              образовании в России проводит экскурсию. Вы можете услышать от
              гида интересные истории о культуре, традициях, еде и многом
              другом.
            </p>
          </div>

          <div className="p-0 my-3">
            <h2 className="mt-2 deco-text">Маршрут</h2>
            <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">День1</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 row p-0 mx-auto">
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src={sigiriyapic}
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">
                            Пидурангала или Сигирия
                          </p>
                        </Card.Title>
                        <Card.Text>
                          Скала Сигирия – одно из самых знаковых мест Шри-Ланки.
                          Вы можете подняться на скалу Сигирия или подняться на
                          скалу Пидурангала, чтобы добраться до смотровой
                          площадки и увидеть Сигирию.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src={elephantpic}
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Кормление слона/ов</p>
                        </Card.Title>
                        <Card.Text>
                          Мы посетим слоновий частный питомник, где вы сможете
                          покормить слона/ов, искупаться со слонами и даже
                          покататься на слонах, если будет время*.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src={dambullapic}
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">
                            Золотой Храм Дамбулла
                          </p>
                        </Card.Title>
                        <Card.Text>
                          Фото-остановка на 15 минут у буддийского храма в
                          Дамбулле, где расположена золотая статуя.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src="https://travelinfosrilanka.com/wp-content/uploads/2023/09/Spice-Garden-Sri-Lanka.jpg"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Сад Аюрведа</p>
                        </Card.Title>
                        <Card.Text>
                          Посещайте Аюрведический сад на Шри-Ланке, где можно
                          увидеть различные уникальные растения и получить
                          различные лечебные процедуры и лекарства.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src={ambuluwawapic}
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Башня Амбулувава</p>
                        </Card.Title>
                        <Card.Text>
                          Башня Амбулувава была возведена в 2009 году, ее высота
                          составляет 48м. С вершины башни открывается панорамный
                          вид на 360 градусов на окрестные города, долины и
                          реки, вечнозеленые леса.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src="https://live.staticflickr.com/554/19550095999_2242e0bca3_b.jpg"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Чайная плантация</p>
                        </Card.Title>
                        <Card.Text>
                          Неудивительно, что лучший чай в мире родом из
                          Шри-Ланки. На следующей остановке вы сможете увидеть
                          чайные плантации, чайную фабрику и попробовать лучшую
                          чашку чая в мире.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src="http://dimg04.c-ctrip.com/images/200b0i0000009pdh1253B.jpg"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Отель в Рамбоде</p>
                        </Card.Title>
                        <Card.Text>
                          Остановка на ночь в отеле в Рамбоде с видом на горы и
                          водопад**.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>День 2</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 row p-0 mx-auto">
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        src="https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/39/a4/db.jpg"
                        className="card-image"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Водопад Рамбода</p>
                        </Card.Title>
                        <Card.Text>
                          Водопад Рамбода Элла — потрясающий водопад высотой 358
                          футов — 11-й по высоте водопад в стране и 729-й в
                          мире.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        src="https://live.staticflickr.com/5550/29995612220_eb398b7d43_k.jpg"
                        className="card-image"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Город Нувара-Элия</p>
                        </Card.Title>
                        <Card.Text>
                          Самый красивый город Шри-Ланки – Нувара-Элия, также
                          известный как Маленькая Англия. Ваш гид проведет вас
                          на мини-экскурсию по городу***.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src="https://img.freepik.com/premium-photo/travel-by-public-train-around-island-sri-lanka-train-travels-through-mountains-tea-plantations-scenic-railway_105751-12037.jpg?w=2000"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Поезд</p>
                        </Card.Title>
                        <Card.Text>
                          Самая живописная поездка на поезде в мире через чайные
                          плантации, водопады, горы и фермы.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src={ninepic}
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Девятиарочный мост</p>
                        </Card.Title>
                        <Card.Text>
                          Знаменитый мост с девятью арками делает его очень
                          живописным местом, тем более что он расположен в
                          густых джунглях и сельскохозяйственных угодьях.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src="https://live.staticflickr.com/1472/24820142825_e306bb3ea8_k.jpg"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Малый Пик Адама</p>
                        </Card.Title>
                        <Card.Text>
                          Поход на Малый пик Адама в Шри-Ланке — одно из самых
                          эпических занятий в Элле. На вершину можно легко
                          подняться, так как есть отмеченная тропа, ведущая на
                          гору, с множеством указателей, указывающих путь.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      style={{ width: "24rem" }}
                      className="p-0 mx-auto col-12 col-md-3 my-2"
                    >
                      <Card.Img
                        variant="top"
                        className="card-image"
                        src="https://d1ynolcus8dvgv.cloudfront.net/2019/11/MAD1003-800-450.jpg"
                      />
                      <Card.Body>
                        <Card.Title>
                          <p className="my-auto font-800">Зиплайн</p>
                        </Card.Title>
                        <Card.Text>
                          Двухпроводная зип-линия протянулась более чем на
                          полкилометра, скользит со скоростью 80 км/ч и
                          предлагает вид с высоты птичьего полета на красивые
                          холмы острова.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Транспорт со всеми расходами</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Русскоговорящий гид</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Проживание в отеле 4*</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Завтрак + ужин</p>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Обеды ($5-10)</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">
                  Входные билеты в Сигирию {`($36)`}
                </p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Зиплайн {`($30)`}</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Купание, катание на слоне</p>
              </div>
            </div>
          </div>
          <div className="p-1 col-12 mx-auto deco-text my-3">
            <h2 className="mt-2">Стоимость</h2>
            <div className="col-12 p-0">
              <Pricetable />
            </div>
          </div>
          <div className="p-1 col-12 mx-auto deco-text my-3">
            <h2 className="mt-2">Галерея</h2>
            <div className="col-12 p-0">
              <Gallary8 />
            </div>
          </div>
          <div className="p-1 row col-12 deco-text my-3 mx-auto">
            <div className="p-0 col-12">
              <Booktour tour="2-х дневная Экскурсия - Yamu Explore" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details