import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck,  FaTimes,  } from "react-icons/fa";


import Photogrid from './Photogrid';
import Pricetable from './Pricetable';
import Booktour from '../../Minicomponents/Booktour';

const Details = () => {
    return (
      <div className="container p-0 d-flex">
        <div className="col-12 my-3 p-0 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">12 часов</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Стоимость</h5>
                <p className="text-center my-auto">от $80</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Групповые туры</h5>
                <p className="text-center my-auto">По четвергам</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              Шри-Ланка — одна из немногих стран, куда можно отправиться на
              сафари за пределами Африки. Национальный парк Яла является самым
              популярным среди всех остальных сафари-парков Шри-Ланки благодаря
              тому, что в одном единственном парке можно увидеть множество
              различных животных, таких как леопарды, слоны, крокодилы, медведи
              и многое другое. Тур «Yamu Safari - Yala» добавит незабываемые
              воспоминания о дикой природе в ваш отдых на Шри-Ланке.
            </p>
          </div>

          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Маршрут</h2>
            <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">
                  Выезд 03:00
                </Accordion.Header>
                <Accordion.Body>
                  <h5 className="deco-text">Хиккадува : 02.45</h5>
                  <h5 className="deco-text">Унаватуна : 03.10</h5>
                  <h5 className="deco-text">Велигама / Мирисса : 03.30</h5>
                  Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                  вас. Если водитель не может найти ваш отель с помощью карт
                  Google, он может позвонить вам. Вы должны оплатить полную
                  сумму при встрече с водителем.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="deco-text">
                  Встреча с водителем сафари
                </Accordion.Header>
                <Accordion.Body>
                  Вы встретитесь с водителем сафари-джипа в городке
                  Тиссамахарама. Вы должны оплатить полную сумму при встрече с
                  водителем.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Национальный парк Яла</Accordion.Header>
                <Accordion.Body>
                  Вы прибудете ко входу в парк около 06:15. Ваш водитель купит
                  вам входные билеты в кассе. После покупки билетов начинается
                  ваше сафари. Водитель покажет вам как можно больше диких
                  животных. Выходить из джипа нельзя без разрешения водителя.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Памятник цунами </Accordion.Header>
                <Accordion.Body>
                  15-минутная остановка на пляже возле памятника цунами,
                  построенного в память о цунами 2004 года. Водитель угостит вас
                  закусками, фруктами и напитками.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Выезд из сафари-парка</Accordion.Header>
                <Accordion.Body>
                  Сафари заканчивается около 10.30. Ваш водитель отвезет вас в
                  ресторан чтобы вы позавтракали.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Маяк дондра</Accordion.Header>
                <Accordion.Body>
                  На обратном пути к южному побережью мы остановимся у самой
                  южной точки Шри-Ланки - маяка Дондра.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Coconut Tree Hill</Accordion.Header>
                <Accordion.Body>
                  Отпуск на Шри-Ланке будет неполной без фото с Coconut tree
                  hill Mirissa.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Возвращение в ваш отель</Accordion.Header>
                <Accordion.Body>
                  Экскурсия заканчивается около 15:30. Ваш водитель отвезет вас
                  в отель.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Трансфер со всеми расходамы</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Сафари джип</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Опытный сафари водитель</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Закуски, фрукты и вода</p>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Завтрак / Обед</p>
              </div>
            </div>
          </div>
          <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Gallary</h2>
            <Photogrid />
          </div>
          <div className="p-1 col-12 mx-auto deco-text my-3">
            <h2 className="mt-2">Стоимость</h2>
            <div className="col-12 p-0"> 
              <Pricetable />
            </div>
          </div>
          <div className="p-1 row col-12 deco-text my-3 mx-auto">
            <div className="p-0 col-12">
              <Booktour tour="Сафари в национальном парке Яла - Yamu Safari Yala" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details